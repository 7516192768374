import React from 'react';
import { Helmet } from 'react-helmet';

function GoogleTagManager() {
    return (
        <Helmet>
            <noscript>
                {`<iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-57KR8T7P"
                    height="0"
                    width="0"
                    style="display:none;visibility:hidden">
                </iframe>`}
            </noscript>
        </Helmet>
    );
}

export default GoogleTagManager;
